<template>
    <v-container>
        <h1> <textloader-component textKey="NyKurschemaRubrik"/> </h1>
        <paragraph-component> <textloader-component textKey="NyKurschemaBrödtext"/> </paragraph-component>
    </v-container>
</template>

<script>
import ParagraphComponent from '@/components/ParagraphComponent'
import TextloaderComponent from '@/components/TextloaderComponent'
export default {
  components: {
    ParagraphComponent,
    TextloaderComponent
  },
  data: () => {
    return {
      // Lista med bild-länkar
      urls: [
        require('@/assets/NyStudent/OhmMottagningen/Bild1.jpg'),
        require('@/assets/NyStudent/OhmMottagningen/Bild2.jpg'),
        require('@/assets/NyStudent/OhmMottagningen/Bild3.jpg')
      ]
    }
  }
}
</script>
